/* eslint-disable global-require, unicorn/consistent-function-scoping,
unicorn/prefer-node-remove, operator-linebreak, unicorn/consistent-destructuring */

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/it';
import CssBaseline from '@material-ui/core/CssBaseline';
import StylesProvider from '@material-ui/styles/StylesProvider';
import { existsEnvironmentValues } from '@pe-libs/react-components/build/lib/environment';
import { getLocaleFileName } from '@pe-libs/react-components/build/lib/intl/helper';
import PeIntlProvider from '@pe-libs/react-components/build/lib/intl/IntlProvider';
import { LocaleFileType, MessagesType } from '@pe-libs/react-components/build/lib/intl/settings';
import jss from '@pe-libs/react-components/build/lib/theme/jss';
import PeThemeProvider from '@pe-libs/react-components/build/lib/theme/ThemeProvider';
import * as Sentry from '@sentry/nextjs';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React from 'react';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import { getLang } from '#helpers/query';
import { cookiesChanged, setUserIntl } from '#modules/app/actions';
import { getUserLocale, withDarkMode } from '#modules/app/selectors';
import { pageView } from '#utils/tracking';
import fontData from '../fonts';
import localeMessages from '../locales';
import deDe from '../locales/de-DE.json';
import enUS from '../locales/en-US.json';
import esES from '../locales/es-ES.json';
import frFR from '../locales/fr-FR.json';
import itIT from '../locales/it-IT.json';
import nlNL from '../locales/nl-NL.json';
import ptPT from '../locales/pt-PT.json';
import { wrapper } from '../store';
import { StateType } from '../types';

const CookieBot = dynamic(() => import('#components/CookieBot'), { ssr: false });

export type WindowWithCookieBot = {
  Cookiebot: {
    consented: boolean;
    onaccept: () => void;
    submitConsent: () => void;
    consent: { necessary: boolean; stamp: string };
  };
} & Window;

declare const window: WindowWithCookieBot;

if (typeof window === 'undefined') {
  // server side code
  existsEnvironmentValues(['API_HOST', 'WWW_HOST', 'RATING_CRAWLER_HOST']);
}

if (typeof window !== 'undefined') {
  // client side code
  require('@webcomponents/shadydom');
}

const getSentryEnvironment = () => {
  const host = window && window.location.host;

  if (host.endsWith('provenexpert.com')) {
    return 'production';
  }

  if (host.endsWith('stage.provenexpert.dev')) {
    return 'stage';
  }

  return 'dev';
};

const tagManagerArguments = {
  gtmId: 'GTM-PKSQFV9',
};

type Props = {
  state: StateType;
  intl: {
    messages: MessagesType;
  };
};

class PeApp extends App<Props> {
  state = {
    isCookiesAccepted: false,
  };

  static getInitialProps = wrapper.getInitialAppProps((store) => async ({ Component, router, ctx }) => {
    const context = {
      ...ctx,
      store,
      router,
    };
    const intl: Props['intl'] = {
      messages: {},
    };

    const pageProperties = Component.getInitialProps ? await Component.getInitialProps(context) : {};
    const cookies = new Cookies(context.req?.headers?.cookie);

    if (typeof window === 'undefined') {
      const langParameter = getLang(ctx.query);
      const userLocale = getUserLocale(store.getState());
      const browserLanguages = context.req?.headers['accept-language']?.split(',');
      const getLocale = (languages?: string[]) => {
        if (languages && languages[0] === 'de') {
          return 'de-DE';
        }

        if (languages && languages[0] === 'es') {
          return 'es-ES';
        }

        if (languages && languages[0] === 'fr') {
          return 'fr-FR';
        }

        if (languages && languages[0] === 'it') {
          return 'it-IT';
        }

        if (languages && languages[0] === 'pt') {
          return 'pt-PT';
        }

        if (languages && languages[0] === 'nl') {
          return 'nl-NL';
        }

        return 'en-US';
      };
      const locale = userLocale ?? langParameter ?? cookies.get('registerlocale') ?? getLocale(browserLanguages);

      const cookieBot: string = cookies.get('CookieConsent') as string;

      const isCookiesAccepted =
        cookieBot?.includes('necessary:true,preferences:true,statistics:true,marketing:true') ?? false;

      store.dispatch(cookiesChanged(isCookiesAccepted));
      store.dispatch(setUserIntl({ locale }));
      intl.messages = localeMessages[getLocaleFileName(locale)];
    }

    return {
      pageProps: pageProperties,
      state: store.getState(),
      intl,
    };
  });

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (process.env.NODE_ENV === 'production') {
      if (typeof window !== 'undefined') {
        TagManager.initialize(tagManagerArguments);
      }

      Sentry.init({
        environment: getSentryEnvironment(),
        dsn: 'https://e76a7cee645146f4956820f1833fcc08@sentry.tools.provenexpert.dev/16',
        tracesSampleRate: 0.05,
      });
    }

    this.props.router.events.on('routeChangeComplete', PeApp.handleRouteChange);
  }

  static handleRouteChange = (url: URL) => {
    if (process.env.NODE_ENV === 'production') {
      pageView(url);
    }
  };

  static loadMessages = (locale: LocaleFileType) => {
    const localeMap = {
      'en-US': enUS,
      'de-DE': deDe,
      'es-ES': esES,
      'fr-FR': frFR,
      'it-IT': itIT,
      'pt-PT': ptPT,
      'nl-NL': nlNL,
    };

    return localeMap[locale];
  };

  componentWillUnmount() {
    this.props.router.events.off('routeChangeComplete', PeApp.handleRouteChange);
  }

  render() {
    const { Component, state, intl } = this.props;
    const countryLang = state?.app?.settings?.locale?.slice(0, 2);

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
          <meta name="HandheldFriendly" content="true" />
          <link rel="stylesheet" href="https://www.provenexpert.com/css/google-stars.css" />
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="e89874dc-49a1-4fa1-9cec-8c95488ed95c"
            type="text/javascript"
            data-culture={countryLang}
            async={true}
          />
        </Head>
        <StylesProvider jss={jss}>
          <PeThemeProvider selector={withDarkMode} fontData={fontData}>
            <PeIntlProvider initMessages={intl.messages} loadMessages={PeApp.loadMessages}>
              <CssBaseline />
              <Component {...this.props.pageProps} />
              <CookieBot />
              <ToastContainer
                autoClose={false}
                position="top-center"
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={true}
              />
            </PeIntlProvider>
          </PeThemeProvider>
        </StylesProvider>
      </>
    );
  }
}

export default wrapper.withRedux(PeApp);
