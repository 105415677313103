import { getPublicEnvironmentValue } from '@pe-libs/react-components/build/lib/environment';
import { request } from '@pe-libs/react-components/build/lib/store/api';
import { NextPageContext } from 'next';
import { StateType } from '../../../types';
import { externalRatingCrawlerCountSchema } from '../schemas/externalRating';

const externalRatingAPI = (state: StateType) => {
  const ratingHost = getPublicEnvironmentValue('RATING_CRAWLER_HOST');
  const locale = state.app.settings?.locale ?? 'de-DE';

  return {
    getExternalRatingCrawlerCount: async ({ contextRequest }: { contextRequest?: NextPageContext['req'] }) =>
      request({
        locale,
        contextRequest,
        path: `${ratingHost}/q/v1/public-platforms-count`,
        method: 'GET',
        schema: externalRatingCrawlerCountSchema,
        withAuth: false,
      }),
  };
};

export default externalRatingAPI;
